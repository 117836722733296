<template>
  <card class="mx-auto my-20">
    <template #title>Forgot password</template>
    <template>
      <template v-if="!isSent">
        <div class="mx-2 flex flex-col gap-2 justify-center">
          <form-input
            type="email"
            placeholder="Email"
            label="Email"
            v-model="email"
          />
        </div>

        <div v-if="errors">
          <p
            v-for="(value, index) in errors"
            :key="index"
            class="text-red-500 text-xs px-2"
          >
            {{ value }}
          </p>
        </div>
        <t-button
          primary
          class="mx-16 mt-6"
          :loading="isLoading"
          @click="tryInitRecover()"
        >
          Reset password
        </t-button>
      </template>
      <template v-else>
        <div class="mx-2 flex flex-col gap-2 justify-center">
          <p class="pt-2 px-5 text-center text-xs">
            We have sent you password reset link,
            <br />
            lease check you email
          </p>
        </div>
        <t-button primary class="mx-16 mt-6" @click="$router.push('/')">
          CLOSE
        </t-button>
      </template>
      <div class="flex flex-row items-center mx-auto">
        <t-button link tag="router-link" to="/signup"
          >Create an account</t-button
        >
        <p class="text-xs">|</p>
        <t-button link tag="router-link" to="/signin">Login</t-button>
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/Card.vue";
import FormInput from "@/components/core/FormInput.vue";
import TButton from "@/components/core/Button.vue";

import { mapGetters } from "vuex";

export default {
  name: "Recover",
  components: {
    Card,
    FormInput,
    TButton,
  },
  data() {
    return {
      email: "",
      isSent: false,
    };
  },
  computed: {
    ...mapGetters({
      errors: "auth/errors",
      isLoading: "auth/isLoading",
    }),

    fieldsNotEmpty() {
      return this.email && this.email.length > 0;
    },
  },
  methods: {
    async tryInitRecover() {
      if (this.fieldsNotEmpty) {
        await this.$store.dispatch("auth/initRestore", {
          email: this.email,
        });

        await this.commitSent();
      } else {
        await this.$store.commit("auth/SET_ERRORS", {
          detail: "Email field should not be empty",
        });
      }
    },

    commitSent() {
      if (!this.errors) {
        this.isSent = true;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("auth/SET_ERRORS", null);
  },
};
</script>
